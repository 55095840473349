<template>
  <div class="profile-page-container-wrapper">
    <div class="profile-page-container">
      <v-container v-if="profileData?.mainPhotoUrl" class="image-wrapper">
        <img
          :src="apiUrl + profileData?.mainPhotoUrl"
          alt="Profile Image"
          class="profile-image"
        />
      </v-container>
      <div class="small-text for-memory">Ku pamięci</div>
      <h2 class="big-text">
        <span>{{ profileData?.firstName }} {{ profileData?.secondName || '' }}</span><br>
        <span>{{ profileData?.lastName }}</span>
      </h2>
      <div class="text-group">
        <p class="small-text">{{ formatDate(profileData?.birthDate) }}</p>
        <p class="small-text">{{ profileData?.birthPlace }}</p>
      </div>

      <v-icon class="divider-icon-wrapper">
        <img class="divider-icon" alt="" src="@/assets/images/icon-star-divider-80x8.svg">
      </v-icon>

      <div class="text-group">
        <p class="small-text">{{ formatDate(profileData?.deathDate) }}</p>
        <p class="small-text">{{ profileData?.deathPlace }}</p>
      </div>

      <button class="btn-history" @click="$emit('scroll-to-second-page', 1)">
        <span>Poznaj historię</span>
        <v-icon class="icon">mdi-arrow-down</v-icon>
      </button>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CoverPage',
  props: {
    profileData: {
      type: Object,
      default: () => ({})
    }
  },
  emits: ['scroll-to-second-page'],
  data() {
    return {
      apiUrl: process.env.VUE_APP_API_URL
    };
  },
  methods: {
    formatDate(date) {
      if (!date?.year) {
        return '';
      }

      if (!date.month) {
        return `${date.year}`;
      }

      if (!date.day) {
        return `${date.month}.${date.year}`;
      }

      return `${date.day}.${date.month}.${date.year}`;
    }
  }
}
</script>

<style lang="scss" scoped>
.for-memory {
  margin-bottom: 4px;
}

.profile-page-container {
  height: auto !important;
}

.image-wrapper {
  padding-bottom: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.profile-image {
  max-width: 150px;
  max-height: 200px;
  height: 100%;
  width: 100%;
  border-radius: 50%;
  object-fit: cover;
  aspect-ratio: 3 / 4;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);

  @media (min-width: 768px) {
    max-width: 200px;
    max-height: 300px;
  }
}

h2 {
  font-size: 32px; /* Adjusted to match the design */
  margin-bottom: 16px; /* Space between name and surname */
  line-height: 48px;
}
</style>
